import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import './App.scss'
import Projects from './components/Projects/Projects';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack, Dashboard, EmojiEvents, Forest, Person, Settings, Wallet } from '@mui/icons-material';
import RegistrationForm from './components/registration/RegistrationForm';
import Leaderboard from './components/leaderboard/Leaderboard';
import ProjectDashboardPage from './components/prjectdashboard/ProjectDashboardPage';
import MyProfile from './components/profile/MyProfile';
import mainLogo from './assets/mainLogo.png'
import Marketplace from './components/prjectdashboard/Marketplace';
import Profile from './components/userProfile/Profile';
import Checkout from './components/checkout/Checkout';
import SignIn from './components/signIn/SignIn';
import AdminPanel from './components/admin/AdminPanel';
import UploadImage from './components/upload/UploadImage';
import SignInDemand from './SiteComponents/signindemand/SignInDemand';
import { useDispatch, useSelector } from 'react-redux';
import { setUserAuthentication } from './redux/actions';
import Test from './Test';
import OtherUser from './components/otherUser/OtherUser';
import './i18n.js'
import { CheckoutForm } from './components/checkout/CheckoutForm.jsx';
import Return from './components/checkout/Return.jsx';
import Referals from './components/referals/Referals.jsx';
import WalletPage  from './components/wallet/WalletPage.jsx';
const drawerWidth = 240;
const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function App() {
  const navigation = [
    { link: 'Forest', icon: <Forest /> },
    { link: 'Marketplace', icon: <Dashboard /> },
    { link: 'Leaderboard', icon: <Person /> },
    { link: 'Referals', icon: <EmojiEvents /> },
    { link: 'Wallet', icon: <Wallet /> },
    { link: 'Settings', icon: <Settings /> },
    { link: 'backBtn', icon: <ArrowBack /> }
  ]
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [open, setOpen] = React.useState(false);
  const [isLightMode, setIsLightMode] = React.useState(true);
  const location = useLocation();
  const splittedLocation = location.pathname.split('/');
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [lang, setLang] = React.useState('en');
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    if (lang === 'en') {
      setLang('ge');
      i18n.changeLanguage('ge');
    } else {
      setLang('en');
      i18n.changeLanguage('en');
    }
  };

  // const authenticateUser = async () => {
  //   try {
  //     const response = await fetch('https://app.nftforest.ge/profile/logged_in.php', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${apiKey}`,
  //         'User-Agent': 'EricLystra',
  //         'x-auth': 'NFTForest',
  //         'x-api-key': apiKey,
  //       },
  //       mode: 'cors',
  //       credentials: 'include'
  //     });

  //     if (response.ok) {
  //       const userData = await response.json();
  //       const isAuthenticated = userData.alive === 'yes';
  //       dispatch(setUserAuthentication(isAuthenticated));
  //       if (!userData.alive && location.pathname !== '/sign-in') {
  //         navigate('/sign-in');
  //       }
  //     } else {
  //       dispatch(setUserAuthentication(false));
  //     }
  //   } catch (error) {
  //     console.error('Error during authentication:', error);
  //     dispatch(setUserAuthentication(false));
  //   } finally {
  //     // Set loading to false regardless of the outcome
  //     setLoading(false);
  //   }
  // };
  const authenticateUser = React.useCallback(async () => {
    try {
      const response = await fetch('https://app.nftforest.ge/profile/logged_in.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
          'User-Agent': 'EricLystra',
          'x-auth': 'NFTForest',
          'x-api-key': apiKey,
        },
        mode: 'cors',
        credentials: 'include'
      });

      if (response.ok) {
        const userData = await response.json();
        const isAuthenticated = userData.alive === 'yes';
        dispatch(setUserAuthentication(isAuthenticated));
        if (!userData.alive && location.pathname !== '/sign-in') {
          navigate('/sign-in');
        }
      } else {
        dispatch(setUserAuthentication(false));
      }
    } catch (error) {
      console.error('Error during authentication:', error);
      dispatch(setUserAuthentication(false));
    } finally {
      setLoading(false);
    }
  }, [dispatch, location.pathname, navigate]);


  React.useEffect(() => {
    authenticateUser();
  }, [authenticateUser]);

  // const authenticateUser = async () => {
  //   try {
  //     // Make an API call to check the user's authentication status
  //     const response = await fetch('https://mocki.io/v1/182de804-5bbb-40ec-a785-0698d5db4d5f', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         // 'Authorization': `Bearer ${apiKey}`,
  //         // 'User-Agent': 'EricLystra',
  //         // 'x-auth': 'NFTForest',
  //         // 'x-api-key': apiKey,
  //       },
  //       // mode: 'cors',
  //       // credentials: 'include'
  //     });

  //     if (response.ok) {
  //       const userData = await response.json();
  //       console.log(userData);
  //       dispatch(setUserAuthentication(userData.alive === 'yes'));

  //       // Check authentication and navigate here
  //       if (!userData.alive && location.pathname !== '/sign-in') {
  //         navigate('/sign-in');
  //       }
  //     } else {
  //       dispatch(setUserAuthentication(false));

  //       // Navigate here for the case when response is not ok
  //       if (location.pathname !== '/sign-in') {
  //         navigate('/sign-in');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error during authentication:', error);
  //     dispatch(setUserAuthentication(false));

  //     // Navigate here for the case of an error
  //     if (location.pathname !== '/sign-in') {
  //       navigate('/sign-in');
  //     }
  //   } finally {
  //     // Set loading to false regardless of the outcome
  //     setLoading(false);
  //   }

  // };
  // React.useEffect(() => {
  //   authenticateUser();
  // }, []);
  // React.useEffect(() => {
  //   authenticateUser();
  // }, [dispatch, location, authenticateUser]);



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };




  return (
    <div className='main' style={{ '--primary-color': isLightMode ? '#ffffff' : '#011827', '--primary-font-color': isLightMode ? '#0B0B0B' : '#FAFAFA' }}>
      <div className='mainInside'>
        <div className='Header'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={!open ? handleDrawerOpen : handleDrawerClose}
            edge="start"
            sx={{
              marginRight: 5
            }}
          >
            <MenuIcon />
          </IconButton>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className='logo' variant="h6" noWrap component="div">
              NFTforest
            </Typography>
            <img className='logo_img' src={mainLogo} alt='Logo' />
          </div>
          {/* <div className='search_wrapper'>
            <input placeholder='Search' className='searchMain' />
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="feather feather-search" viewBox="0 0 24 24">
              <defs></defs>
              <circle cx="11" cy="11" r="8"></circle>
              <path d="M21 21l-4.35-4.35"></path>
            </svg>
          </div> */}
          <div className="buttons_right">
            <button style={{ cursor: 'pointer' }} className="mode-switch active" onClick={() => { if (isLightMode) { setIsLightMode(false) } else { setIsLightMode(true) } }} title="Switch Theme">
              <svg className="moon" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" width="24" height="24" viewBox="0 0 24 24">
                <defs></defs>
                <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
              </svg>
            </button>
            <IconButton onClick={() => changeLanguage()}>
              {lang === 'en' ? 'ge' : 'en'}
            </IconButton>
            {/* <div className='line'></div> */}
            <Link to={'/'}>
              <button className="profile-btn">
                <Person />
                {/* <img className='profile_img' src='https://picsum.photos/30' alt='...' /> */}
                <span className='name'></span>
              </button>
            </Link>
          </div>
        </div>
        <div className='content'>
          <Drawer variant="permanent" open={open} className={`sideMenu ${open ? 'drawerActive' : ''}`}>
            <List className='drawer_list'>
              {navigation.map((text, index) => (
                text.link === 'backBtn' && open ?
                  <ListItem onClick={handleDrawerClose}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          padding: '10px'
                        }}
                      >
                        {text.icon}
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  : text.link !== 'backBtn' ?
                    <Link key={index} to={`/${text.link}`} style={{ textDecoration: 'none' }}>
                      <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              backgroundColor: splittedLocation[1] === text.link ? 'gray' : '',
                              borderRadius: '50%',
                              padding: '10px'
                            }}
                          >
                            {text.icon}
                          </ListItemIcon>
                          <ListItemText primary={text.link === '' ? t('Forests') : t(`${text.link}`)} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                    : ''
              ))}
            </List>

          </Drawer>
          <div className='routes'>
            {loading ?
              <p>Loading...</p>
              :
              <Routes>
                {isAuthenticated === true ?
                  <>
                    <Route index path='/' element={<Profile />} />
                    <Route path='/Forest' element={<Projects />} />
                    <Route path='/projectDashboard' element={<ProjectDashboardPage />} />
                    <Route path='/register' element={<RegistrationForm />} />
                    <Route path='/Leaderboard' element={<Leaderboard />} />
                    <Route path='/Marketplace' element={<Marketplace />} />
                    <Route path='/Settings' element={<MyProfile />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/checkout/:price' element={<Checkout />} />
                    <Route path='/sign-in' element={<SignIn />} />
                    <Route path='/admin' element={<AdminPanel />} />
                    <Route path='/uploadImage' element={<UploadImage />} />
                    <Route path='/users/:uuid' element={<OtherUser />} />
                    <Route path="/checkout" element={<CheckoutForm />} />
                    <Route path="/return" element={<Return />} />
                    <Route path="/Referals" element={<Referals />} />
                    <Route path="/Wallet" element={<WalletPage />} />
                  </>
                  :
                  <>
                    <Route path='/sign-in' element={<SignIn />} />
                    <Route path='/register' element={<RegistrationForm />} />
                    <Route path='*' element={<SignInDemand />} />
                    <Route path='/uploadImage' element={<UploadImage />} />
                    <Route path='/Test' element={<Test />} />
                  </>

                }
                {/* <Route path='/Test' element={<Test />} /> */}
              </Routes>
            }

          </div>
        </div>
      </div>
    </div>
  );
}