import React from 'react'
import '../profile.scss'

const Trees = ({ treesArray }) => {
  return (
    <div className='trees'>
      <ul>
        {treesArray && treesArray.map((tree) => (
          <li>tree {tree.traaName}, id: {tree.treeId}</li>
        ))}
      </ul>
    </div>
  )
}

export default Trees