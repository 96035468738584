import { CardMedia } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const LazyLoadImage = ({ src, alt, placeholder, ...props }) => {
    const [isVisible, setIsVisible] = useState(false);
    const imgRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        observer.observe(imgRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div ref={imgRef} style={{ height: '100%', width: '100%' }}>
            {isVisible ? (
                <CardMedia component="img" src={src} alt={alt} {...props} />
            ) : (
                <div style={{ height: '100%', backgroundColor: '#ccc' }}>{placeholder}</div>
            )}
        </div>
    );
};
export default LazyLoadImage;