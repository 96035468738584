// rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "./authReducer"; // Adjust the path based on your actual file structure

const rootReducer = combineReducers({
  auth: AuthReducer, // Use AuthReducer as the reducer for the 'auth' slice of state
  // Add other reducers if needed...
});

export default rootReducer;
