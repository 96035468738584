import { Button, FormControl, Input, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../profile.scss'

const PaymentInfo = () => {
    const [formData, setFormData] = useState({
        iban: '',
        cardHolder: '', // ISO format for date
    });

    const [editMode, setEditMode] = useState(false);
    const [initialData, setInitialData] = useState({});
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const response = await fetch('https://app.nftforest.ge/settings/payments.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer YOUR_API_KEY',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const initialFormData = {
                        iban: data[0].iban,
                        cardHolder: data[0].cardHolder
                    };

                    setFormData(initialFormData);
                    setInitialData(initialFormData); // Store the initial data for comparison
                } else {
                    console.error('Failed to fetch initial data');
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();
    }, []);

    const hasFormDataChanged = () => {
        return JSON.stringify(formData) !== JSON.stringify(initialData);
    };

    const postPaymentData = async () => {
        try {
            const response = await fetch('https://app.nftforest.ge/settings/update/payments.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer YOUR_API_KEY',
                    'x-auth': 'NFTForest',
                    'x-api-key': 'YOUR_API_KEY',
                },
                body: JSON.stringify({
                    iban: formData.iban,
                    cardHolder: formData.cardHolder
                }),
            });

            if (response.ok) {
                console.log('Data saved successfully');
            } else {
                console.error('Failed to save data');
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    }

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };


    const handleEditButtonClick = () => {
        if (editMode && hasFormDataChanged()) {
            // Only post data if form data has changed
            postPaymentData();
        }
        setEditMode(!editMode);
    };

    const handleCancelButtonClick = () => {
        setFormData(initialData); // Reset to initial data
        setEditMode(false);
    };


    // Function to handle form submission
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Add your submission logic here
    //     console.log('Form submitted:', formData);
    //     setEditMode(false); // Disable edit mode after submission
    // };

    return (
        <form className='form'>
            <h4>Payment Information</h4>
            <FormControl>
                <InputLabel htmlFor="iban">IBAN</InputLabel>
                <Input
                    type="text"
                    id="iban"
                    name="iban"
                    value={formData.iban}
                    onChange={handleInputChange}
                    disabled={!editMode}
                />
            </FormControl>
            <FormControl>
                <InputLabel htmlFor="cardHolder">Card Holder</InputLabel>
                <Input
                    type="text"
                    id="cardHolder"
                    name="cardHolder"
                    value={formData.cardHolder}
                    onChange={handleInputChange}
                    disabled={!editMode}
                />
            </FormControl>
            <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditButtonClick}
                >
                    {editMode ? 'Save' : 'Edit'}
                </Button>
                {editMode && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancelButtonClick}
                    >
                        Cancel
                    </Button>
                )}
            </div>
        </form>
    );
};

export default PaymentInfo;
