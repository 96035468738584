// CheckoutForm.js
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';


export const CheckoutForm = () => {
  // const stripe = useStripe();
  // const elements = useElements();
  const stripePromise = loadStripe("pk_live_51OklWZCkMbxWxxy8MAcV2sgcVp4xQU5yCAkWD7Dfl9cNU1o4VdgT6soyW0WmCdJjmnwpzfGvXMdtvYI9yhrKFzm300xerejG0C");
  const [options, setOptions] = useState(null);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const clientSecret = await fetchClientSecret();
        setOptions(clientSecret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
        // Handle error
      }
    };

    fetchOptions();
  }, []);

  const fetchClientSecret = async () => {
    try {
      const response = await fetch("/cashola/checkout.php", {
        method: "GET"
      });

      if (!response.ok) {
        throw new Error('Failed to fetch client secret');
      }

      const data = await response.json();

      if (!data || !data.clientSecret) {
        throw new Error('Invalid response format: missing clientSecret');
      }
      return data.clientSecret;
    } catch (error) {
      console.error('Error fetching client secret:', error);
      // Handle the error as needed, e.g., show a message to the user or retry
      return null;
    }
  };

  // const fetchClientSecret = useCallback(() => {
  //   // Create a Checkout Session
  //   fetch("/cashola/checkout.php", {
  //     method: "POST",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data.clientSecret);
  // }, []);
  // const fetchClientSecret = async () => {
  //   try {
  //     const response = await fetch("/cashola/checkout.php", {
  //       method: "GET"
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch client secret');
  //     }

  //     const data = await response.json();

  //     if (!data || !data.clientSecret) {
  //       throw new Error('Invalid response format: missing clientSecret');
  //     }
  //     return data.clientSecret;
  //   } catch (error) {
  //     console.error('Error fetching client secret:', error);
  //     // Handle the error as needed, e.g., show a message to the user or retry
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //   fetchClientSecret();
  // }, [])
  // const options = { fetchClientSecret };
  // const options = await fetchClientSecret();

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (elements == null || stripe == null) {
  //     return;
  //   }

  //   // Trigger form validation and wallet collection
  //   const { error: submitError } = await elements.submit();
  //   if (submitError?.message) {
  //     // Show error to your customer
  //     setErrorMessage(submitError.message);
  //     return;
  //   }
  //   const price = 12;
  //   const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
  //   try {
  //     // Fetch client secret from the server
  //     const response = await fetch('https://app.nftforest.ge/cashola/checkout.php', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${apiKey}`,
  //         'User-Agent': 'EricLystra',
  //         'x-auth': 'NFTForest',
  //         'x-api-key': apiKey,
  //       },
  //       mode: 'cors',
  //       credentials: 'include'
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to fetch client secret');
  //     }
  //     const responseData = await response.json();
  //     const client_secret = responseData.clientSecret;

  //     const { error } = await stripe.confirmCardPayment(client_secret, {
  //       payment_method: {
  //         card: elements.getElement(CardElement),
  //         billing_details: {
  //           email: emailInput,
  //         },
  //       },
  //     });

  //     if (error) {
  //       // Show error to your customer
  //       setErrorMessage(error.message);
  //     } else {
  //       // Payment successful
  //       // Redirect or show success message
  //     }
  //   } catch (error) {
  //     // Handle fetch or confirmation errors
  //     console.error('Error:', error);
  //   }
  // };

  return (
    <div id='checkout' className="checkout">
      {options ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ fetchClientSecret: fetchClientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
  // <form onSubmit={handleSubmit} className='px-4'>
  //   <div className='mb-3'>
  //     <label htmlFor="email-input">Email</label>
  //     <div>
  //       <input value={emailInput} onChange={(e) => setEmailInput(e.target.value)} type="email" id="email-input" placeholder='johndoe@gmail.com' />
  //     </div>
  //   </div>
  //   <PaymentElement />
  //   <button type="submit" disabled={!stripe || !elements}>
  //     Pay
  //   </button>
  //   {errorMessage && <div>{errorMessage}</div>}
  // </form>
};
