import React, { useState, useEffect } from 'react';
import { FormControl, Switch, FormGroup, FormControlLabel } from '@mui/material';
import '../profile.scss'

const MyForm = () => {
    const [formData, setFormData] = useState({
        allowEmails: false,
        secretProfile: false,
    });

    useEffect(() => {
        const fetchInitialData = async () => {
            const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
            try {
                const response = await fetch('https://app.nftforest.ge/settings/privacy.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                        'User-Agent': 'EricLystra',
                        'x-auth': 'NFTForest',
                        'x-api-key': apiKey,
                    },
                    mode: 'cors',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json(); // Convert response to JSON
                    setFormData({
                        allowEmails: data[0].allowEmails === 'yes',
                        secretProfile: data[0].secretProfile === 'yes',
                    });
                } else {
                    console.error('Failed to fetch initial data');
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();
    }, []); // Runs only once when the component mounts

    const handleToggleChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };

    const postPrivacyData = async () => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch('https://app.nftforest.ge/settings/update/privacy.php', {
                method: 'POST', // Corrected method to POST
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                body: JSON.stringify({
                    allowEmails: formData.allowEmails ? 'yes' : 'no',
                    secretProfile: formData.secretProfile ? 'yes' : 'no',
                }),
                mode: 'cors',
                credentials: 'include',
            });

            if (response.ok) {
                console.log('Data saved successfully');
            } else {
                console.error('Failed to save data');
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    return (
        <div style={{display:'flex', flexDirection:'column', gap:10}}>
            <FormControl>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.allowEmails}
                                onChange={handleToggleChange}
                                name="allowEmails"
                            />
                        }
                        label="Allow emails"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.secretProfile}
                                onChange={handleToggleChange}
                                name="secretProfile"
                            />
                    }
                    label="Secret profile"
                />
            </FormGroup>
        </FormControl>
        <button onClick={postPrivacyData} className='privacy_button'>SAVE</button> {/* Corrected onClick */}
    </div>
    );
};

export default MyForm;
