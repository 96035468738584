import React, { useState } from 'react'
import './adminpanel.scss'
import Projects from '../Projects/Projects'

const AdminPanel = () => {
    const [inputs, setInputs] = useState(false);
    const toggleInputs = () => {
        if (inputs) {
            setInputs(false);
        } else {
            setInputs(true);
        }
    }
    const [formData, setFormData] = useState({
        name: '',
        subtitle: '',
        begin: '',
        end: '',
        hectares: '',
        trees: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Perform the submission logic here, e.g., send data to server

        console.log('Form data submitted:', formData);
    };

    return (
        <div className='admin_panel'>
            <h3>Admin Panel</h3>
            <div className="projects_live">
                <h3>Projects in progress</h3>
                <Projects />
            </div>
            <div className='add_container'>
                <button onClick={toggleInputs}>+ Add Project</button>
            </div>
            {inputs && <div className='AddProjectForm'>
                <form onSubmit={handleSubmit}>
                    <div className='form_control'>
                        <label htmlFor="name">Project Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='form_control'>
                        <label htmlFor="subtitle">Subtitle:</label>
                        <input
                            type="text"
                            id="subtitle"
                            name="subtitle"
                            value={formData.subtitle}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form_control">
                        <label htmlFor="begin">Start Date:</label>
                        <input
                            type="date"
                            id="begin"
                            name="begin"
                            value={formData.begin}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form_control">
                        <label htmlFor="end">End Date:</label>
                        <input
                            type="date"
                            id="end"
                            name="end"
                            value={formData.end}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form_control">
                        <label htmlFor="hectares">Hectares:</label>
                        <input
                            type="number"
                            id="hectares"
                            name="hectares"
                            value={formData.hectares}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="form_control">
                        <label htmlFor="trees">Number of Trees:</label>
                        <input
                            type="number"
                            id="trees"
                            name="trees"
                            value={formData.trees}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {/* You may include other fields as needed */}
                    <button type="submit">Submit</button>
                </form>
            </div>}
        </div> 
    )
}

export default AdminPanel