import React, { useEffect, useState } from 'react';
import './projects.scss';
import { DynamicFeed, FormatListBulleted, MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';


const projectsInfo = [
    { number: '0%', topic: 'Finished' },
    { number: '0%', topic: 'In progress' },
    { number: '100%', topic: 'Free to reserve' }
]




const Projects = () => {
    const [openedPanel, setOpenedPanel] = useState(0);
    const [data, setData] = useState();
    const togglePanel = (panel) => {
        setOpenedPanel(panel);
    };
    useEffect(() => {
        const ProjectsInfo = async () => {
            const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
            try {
                const response = await fetch('https://app.nftforest.ge/projects/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                        'User-Agent': 'EricLystra',
                        'x-auth': 'NFTForest',
                        'x-api-key': apiKey,
                    },
                    mode: 'cors',
                    credentials: 'include'
                });

                if (response.ok) {
                    const dataJson = await response.json();
                    setData(dataJson);
                } else {
                    console.error('GET request failed');
                }
            } catch (error) {
                console.error('Error during gathering information:', error);
            }
        };
        ProjectsInfo();
    }, []);

    return (
        <div className='projects'>
            <div className="title_line">
                <h2>Projects</h2>
                <h3>Date.</h3>
            </div>
            <div className="control_line">
                <div className="infos">
                    {projectsInfo.map((info, _index) => (
                        <div key={_index} className="info"><span>{info.number}</span>{info.topic}</div>
                    ))}
                </div>
                <div className="control_btns">
                    <button
                        onClick={() => togglePanel(0)}
                        className={`btn_control ${openedPanel === 0 ? 'active' : ''}`}
                    >
                        <FormatListBulleted />
                    </button>
                    <button
                        onClick={() => togglePanel(1)}
                        className={`btn_control ${openedPanel === 1 ? 'active' : ''}`}
                    >
                        <DynamicFeed />
                    </button>
                </div>
            </div>
            <div className={`panel ${openedPanel === 0 ? 'vertical' : 'horizontal'}`}>
                
                {data === undefined ? <p>Loading...</p> : data.map((project, _index) => (
                    <Link to={`/ProjectDashboard`} key={_index} className={`project_single ${openedPanel === 0 ? 'long_one' : 'short_one'}`} style={{ backgroundColor: "#FEE4CB" }}>
                        <div className="upper_line">
                            <h5>{project.begin}</h5>
                            <MoreVert />
                        </div>

                        <div className="titles">
                            <h3>{project.name}</h3>
                            <h6>{project.subtitle}</h6>
                        </div>
                        <div className="progress_wrap">
                            <h5>Progress</h5>
                            <div className="progress_line">
                                <div className='progress finished' style={{ width: `${30}%`, backgroundColor: 'green' }}></div>
                                <div className="progress in_progress" style={{ width: `${30}%`, backgroundColor: 'orange' }}></div>
                            </div>
                        </div>
                        <hr />
                        <Link to={`/Leaderboard`} style={{ zIndex: 5 }} className="others">
                            <div className="top_contributors">
                                <img src="https://picsum.photos/50" alt="..." />
                                <img src="https://picsum.photos/50" alt="..." />
                            </div>
                            <div className="left">
                                {project.days_remaining} Days Left
                            </div>
                        </Link>
                    </Link>

                ))}
            </div>

        </div>
    );
};

export default Projects;
