import React from 'react'
import './checkout.scss'
import { useParams } from 'react-router-dom'
import { ChevronRight, Forest, Token } from '@mui/icons-material';

const Checkout = () => {
    const { price } = useParams();
    const trees = price / 10;
    const tokens = Array.from({ length: trees });

    return (
        <div className='checkout'>
            <div className="upper_line">
                <h4>Payment:</h4>
                <div className="tokens">
                    <Token />
                    <h3>4 Trees</h3>
                    <Forest />
                </div>
                <div className="address">
                    <h3>E-wallet adress: </h3>
                    <ins>https://adalsfmaofmlakm.ckmfl</ins>
                </div>
                <div className="token_keys">
                    <h4>Your NFT keys:</h4>
                    {tokens.map((token) => (
                        <div className="token">
                            <ins>https://fkaffnnlanabbambafnb</ins>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bottom_line">
                <h2>Total :{price}.00 $</h2>
                <button>Pay <ChevronRight /></button>
            </div>
        </div>
    )
}

export default Checkout