import React, { useEffect, useState } from 'react';
import { FormControl, Button, InputAdornment, TextField } from '@mui/material';
import { SocialIcon } from 'react-social-icons';
const getSocialMediaIcon = (name) => {
  switch (name) {
    case 'Facebook':
      return <SocialIcon url='https://facebook.com' />;
    case 'Twitter':
      return <SocialIcon url='https://x.com' />;
    case 'Instagram':
      return <SocialIcon url='https://instagram.com' />;
    case 'LinkedIn':
      return <SocialIcon url='https://linkedin.com' />;
    case 'YouTube':
      return <SocialIcon url='https://youtube.com' />;
    default:
      return null;
  }
};

const SocialMediaForm = () => {
  const [socialMedia, setSocialMedia] = useState([
    { name: 'Facebook', link: '', icon: getSocialMediaIcon('Facebook') },
    { name: 'Twitter', link: '', icon: getSocialMediaIcon('Twitter') },
    { name: 'Instagram', link: '', icon: getSocialMediaIcon('Instagram') },
    { name: 'LinkedIn', link: '', icon: getSocialMediaIcon('LinkedIn') },
    { name: 'YouTube', link: '', icon: getSocialMediaIcon('YouTube') }
  ]);
  const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';

  useEffect(() => {
    // Fetch initial data when component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Fetch initial data from the server
      const response = await fetch('https://app.nftforest.ge/settings/social_media.php', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
          'User-Agent': 'EricLystra',
          'x-auth': 'NFTForest',
          'x-api-key': apiKey,
        },
        mode: 'cors',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // Convert fetched data into the format suitable for state
      const initialData = Object.keys(data).map((key) => ({
        name: key,
        link: data[key],
        icon: getSocialMediaIcon(key), // Function to get icon based on platform name
      }));
      setSocialMedia(initialData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    const updatedSocialMedia = [...socialMedia];
    updatedSocialMedia[index].link = value;
    setSocialMedia(updatedSocialMedia);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const dataToSend = {};
      socialMedia.forEach((profile) => {
        dataToSend[profile.name] = profile.link;
      });
      const response = await fetch('https://app.nftforest.ge/settings/update/social_media.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`,
          'User-Agent': 'EricLystra',
          'x-auth': 'NFTForest',
          'x-api-key': apiKey,
        },
        body: JSON.stringify(dataToSend),
        mode: 'cors',
        credentials: 'include'
      });

      if (response.ok) {
        console.log('Data saved successfully!');
      } else {
        console.error('Failed to save data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <form className='form' onSubmit={handleSubmit}>
      {socialMedia.map((profile, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControl style={{ width: '100%' }}>
            {/* <InputLabel htmlFor={`social-media-link-${index}`}>{profile.name}</InputLabel> */}
            <TextField
              type='url'
              id={`social-media-link-${index}`}
              name="link"
              value={profile.link}
              placeholder={`https://${profile.name}.com`}
              onChange={(e) => handleInputChange(e, index)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {profile.icon}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </div>
      ))}
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

export default SocialMediaForm;
