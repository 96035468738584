import React, { useCallback, useEffect, useState } from 'react';
import forest from '../../assets/forest.jpg';
import '../userProfile/profile.scss';
import { useParams } from 'react-router-dom';
import Trees from '../userProfile/panels/Trees';
import Socials from '../userProfile/panels/Socials';
import Referals from '../userProfile/panels/Referals';

const OtherUser = () => {
    const [navSt, setNavSt] = useState(1);
    const [userData, setUserData] = useState({});
    const { uuid } = useParams();
    // const getInfo = async () => {
    //     const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
    //     try {
    //         const response = await fetch(`https://app.nftforest.ge/users/?username=${username}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${apiKey}`,
    //                 'User-Agent': 'EricLystra',
    //                 'x-auth': 'NFTForest',
    //                 'x-api-key': apiKey,
    //             },
    //             mode: 'cors',
    //             credentials: 'include'
    //         });

    //         if (response.ok) {
    //             const userData = await response.json();
    //             setUserData(userData[0]);
    //             // Handle successful login
    //         } else {
    //             console.error('Failed to fetch');
    //         }
    //     } catch (error) {
    //         console.error('Failed to:', error);
    //     }
    // };



    // const getInfo = async () => {
    //     const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
    //     try {
    //         const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${apiKey}`,
    //                 'User-Agent': 'EricLystra',
    //                 'x-auth': 'NFTForest',
    //                 'x-api-key': apiKey,
    //             },
    //             mode: 'cors',
    //             credentials: 'include'
    //         });

    //         if (response.ok) {
    //             const userData = await response.json();
    //             setUserData(userData);
    //             // Handle successful login
    //         } else {
    //             console.error('Failed to fetch');
    //         }
    //     } catch (error) {
    //         console.error('Failed to:', error);
    //     }
    // }

    // useEffect(() => {
    //     getInfo();
    // }, []);

    const getInfo = useCallback(async () => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                mode: 'cors',
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                setUserData(data);
            } else {
                console.error('Failed to fetch');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }, [uuid]); // Ensure useCallback depends on the uuid to create a stable reference

    useEffect(() => {
        getInfo();
    }, [getInfo]);
    
    const toggleActive = (id) => {
        if (id === navSt) {
            setNavSt(0);
        } else {
            setNavSt(id);
        }
    };

    var nav;
    if (userData !== null) {
        nav = [
            { clickable: true, elem: <Trees treesArray={userData.trees} />, id: 1, num: userData.trees?.length, txt: 'Trees' },
            { clickable: true, elem: <Referals referralsArray={userData.referals} />, id: 2, num: userData.referals?.length, txt: 'Referals' },
            { clickable: true, elem: <Socials socials={userData.SocialMedia} />, id: 3, num: userData.SocialMedia?.socialMediaArray.filter(item => item.link && item.link.trim() !== "").length, txt: 'Soc. media' },
            { clickable: false, id: 4, num: 0, txt: 'CO2 capture rate' },
            { clickable: false, id: 5, num: 0, txt: 'Score' }
        ];
    } else {
        nav = [
            { clickable: true, elem: <Trees />, id: 1, num: 1, txt: 'Trees' },
            { clickable: true, elem: <Referals />, id: 2, num: 12, txt: 'Referals' },
            { clickable: true, elem: <Socials />, id: 3, num: 3, txt: 'Soc. media' },
            { clickable: false, id: 4, num: 0, txt: 'CO2 capture rate' },
            { clickable: false, id: 5, num: 0, txt: 'Score' }
        ];
    }

    return (
        <div className='profile'>
            <div className="profile_widget">
                <div className="profile_bg" style={{ backgroundImage: `url(${forest})` }}>
                    <div className="personal_info">
                        <div className='profile_img_wrap'>
                            {userData ? (
                                <div style={{ backgroundImage: `url("https://app.nftforest.ge/assets/img/${userData.user?.profilePictureSrc}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100%' }}></div>
                            ) : <img src='' alt='userProfile' />}
                        </div>
                        {userData && (
                            <>
                                <h5>{userData.user?.displayName}</h5>
                                <h6>@{userData.user?.username}</h6>
                            </>
                        )}
                    </div>
                </div>
                <div className="profile_nav">
                    {nav && nav.map((btn) => (
                        btn.clickable ? (
                            <div key={btn.id} onClick={() => toggleActive(btn.id)} className={`btn ${navSt === btn.id ? 'active' : ''}`}>
                                <h5>{btn.num}</h5>
                                <p>{btn.txt}</p>
                            </div>
                        ) : (
                            <div key={btn.id} className={`btn nocursor`}>
                                <h5>{btn.num}</h5>
                                <p>{btn.txt}</p>
                            </div>
                        )
                    ))}
                </div>
            </div>
            <div className="profile_panel">
                {userData ? (
                    navSt === 0 ? '' : nav[navSt - 1]?.elem
                ) : (
                    <p>Loading...</p>
                )}{/* Use optional chaining here */}
            </div>
        </div>
    );
};

export default OtherUser;
