import React from 'react'
import '../profile.scss'
import { Link } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'

const Socials = ({ socials }) => (
    <div className='socials'>
        <h3>{socials.surName}, {socials.firstName}</h3>
        {socials.socialMediaArray && socials.socialMediaArray.map((social, index) => (
            <Link to={social.link} className="social" key={index}>
                {social.name === 'Twitter' && social.link !== "" && <SocialIcon  href={social.link} url='https://x.com' />}
                {social.name === 'Facebook' && social.link !== "" && <SocialIcon href={social.link}  url='https://facebook.com' />}
                {social.name === 'Instagram' && social.link !== "" && <SocialIcon href={social.link} url='https://instagram.com' />}
                {social.name === 'LinkedIn' && social.link !== "" && <SocialIcon href={social.link} url='https://linkedin.com' />}
                {social.name === 'YouTube' && social.link !== "" && <SocialIcon href={social.link} url='https://youtube.com' />}


                {/* Add more social media icons/components as needed */}
            </Link>
        ))}
    </div>
)
export default Socials;