import React from 'react'
import './dashboard.scss'
// import Overview from './Overview';
// import Demographics from './Demographics';
import Marketplace from './Marketplace';

const ProjectDashboardPage = () => {
    // const [dashPanel, setDashPanel] = useState(2);

    return (
        <div className='dashboard'>
            <h3>Project Dashboard</h3>
            <div className="dash_tabs">
                {/* <div className={`dash_tab ${dashPanel === 0 ? 'active' : ''}`} onClick={() => setDashPanel(0)}>Overview</div>
                <div className={`dash_tab ${dashPanel === 1 ? 'active' : ''}`} onClick={() => setDashPanel(1)}>Demographics</div> */}
                {/* <div className={`dash_tab ${dashPanel === 2 ? 'active' : ''}`} onClick={() => setDashPanel(2)}>Marketplace</div> */}
            </div>
            <Marketplace />
        </div >
    )
}

export default ProjectDashboardPage