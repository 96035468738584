import React, { useEffect, useState } from 'react';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import './dashboard.scss';
import { ForestSharp } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';


const Hectare = ({ index, onClick, region }) => (
    region !== 'Reserved' ? <div className='hectare' onClick={() => onClick(index)}>
        <h6>Hectar {index}</h6>
        <p>{region}</p>
    </div> : <div className='hectare_reserved'>
        <h6>Hectar {index}</h6>
        <p>{region}</p>
    </div>
)



const Panel = ({ open, anchor, activeHectar, handleTreesPurchaseFn, isOpened, id, handlePopUpClick, selectedTrees, paymentClickChoice, trees }) => {
    const [selectedSponsoredTree, setSelectedSponsoredTree] = useState();
    const navigate = useNavigate();
    return (<div className={`panel ${isOpened ? 'activePanel' : ''}`}>
        {activeHectar !== 4 ?
            <div className='panel_content'>
                {selectedTrees.length > 0 && (
                    <div className='checkoutDiv'>
                        <p>Total Price: ${selectedTrees.length * 50}</p>
                        <Link onClick={handleTreesPurchaseFn} to={`/checkout`}>
                            <button className='pay_btn'>Pay</button>
                        </Link>
                    </div>
                )}
                {/* {trees != null ? trees.map((tree, index) => (
                    // tree.status === "sponsored" ?
                    //     <div
                    //         aria-describedby={id}
                    //         key={index} className='tree'
                    //         onClick={(e) => {
                    //             handlePopUpClick(e);
                    //             setSelectedSponsoredTree(tree);
                    //         }}
                    //         style={{ cursor: 'pointer' }}>
                    //         <ForestSharp sx={{ color: 'green' }} />
                    //     </div> : tree.status === "pending" ? <div aria-describedby={id} key={index} className='tree'>
                    //         <ForestSharp sx={{ color: 'orange' }} />
                    //     </div> :
                    //         <div
                    //             aria-describedby={id}
                    //             key={index}
                    //             className='tree free'
                    //             onClick={() => paymentClickChoice(tree.id)}
                    //         >
                    //             <ForestSharp style={{ color: `${selectedTrees.includes(tree.id) ? 'blue' : 'gray'}`, cursor: 'pointer' }} />
                    //         </div>
                    tree.isMine === 'yes' ?
                        <div
                            aria-describedby={id}
                            key={index}
                            className='tree free'
                            onClick={() => paymentClickChoice(tree.id)}
                        >
                            <ForestSharp style={{ color: `${selectedTrees.includes(tree.id) ? 'blue' : tree.color}`, cursor: 'pointer' }} />
                        </div> : <div
                            aria-describedby={id}
                            key={index}
                            className='tree free'
                            onClick={() => paymentClickChoice(tree.id)}
                        >
                            <ForestSharp style={{ color: `${selectedTrees.includes(tree.id) ? 'blue' : 'gray'}`, cursor: 'pointer' }} />
                        </div>
                )) : "loading"} */}
                {trees ? (
                    trees.map((tree, index) => {
                        // Determine if the tree is selected
                        const isSelected = selectedTrees.includes(tree.id);

                        // Determine the icon color: blue if selected, otherwise use the tree's color
                        var iconColor = isSelected ? 'blue' : tree.color || 'gray';

                        // Determine the click handler
                        let onClickHandler = null;

                        if (tree.isMine === 'yes') {
                            // If the tree is "mine", set onClick to redirect to '/'
                            onClickHandler = () => navigate('/');
                        } else if (tree.status === 'sponsored') {
                            onClickHandler = (e) => {
                                handlePopUpClick(e);
                                setSelectedSponsoredTree(tree);
                            };
                        } else if (tree.clickable === 'yes') {
                            // Clickable tree
                            onClickHandler = () => paymentClickChoice(tree.id);
                        } else if (tree.status === 'pending') {
                            iconColor = tree.color;
                        }

                        // Apply a pointer cursor only if there's a click handler
                        const cursorStyle = onClickHandler ? 'pointer' : 'default';

                        return (
                            <div
                                key={index}
                                className={`tree ${tree.status}`}
                                aria-describedby={`tree-${tree.id}`}
                                onClick={onClickHandler}
                                style={{ cursor: cursorStyle }}
                            >
                                <ForestSharp style={{ color: iconColor }} />
                            </div>
                        );
                    })
                ) : (
                    'Loading...'
                )}
            </div> : <p>ITS HECTARE 4</p>
        }
        <BasePopup id={id} open={open} anchor={anchor}>
            {selectedSponsoredTree ?
                <div className='popupBody'>
                    <Link to={`/users/${selectedSponsoredTree.uuid}`} className='owner' style={{ textAlign: 'center' }}>{selectedSponsoredTree.sponsor_name}</Link>
                    <div style={{
                        backgroundImage: `url(${selectedSponsoredTree.avatar})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        width: '50px',
                        height: '50px',
                    }}></div>
                </div> : console.log(selectedSponsoredTree)
            }
        </BasePopup >
    </div >)
};

const Marketplace = () => {
    const [anchor, setAnchor] = React.useState(null);

    const handleClick = (event) => {
        setAnchor(anchor ? null : event.currentTarget);
    };

    const open = Boolean(anchor);
    const id = open ? 'simple-popper' : undefined;

    const [activeHectare, setActiveHectare] = useState(0);
    const [dashVisible, setDashVisible] = useState(false);
    const [hectares, setHectares] = useState(null);
    const [trees, setTrees] = useState(null);
    const getHectares = async () => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch('https://app.nftforest.ge/hectares/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (response.ok) {
                const hecData = await response.json();
                setHectares(hecData[0]);
                // Handle successful login
            } else {
                console.error('Failed to fetch');
            }
        } catch (error) {
            console.error('Failed to:', error);
        }
    };

    const getTrees = async (index) => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch(`https://app.nftforest.ge/trees/?hectare_id=${index}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (response.ok) {
                const treeData = await response.json();
                setTrees(treeData[0]);
                // Handle successful login
            } else {
                console.error('Failed to fetch');
            }
        } catch (error) {
            console.error('Failed to:', error);
        }
    };

    useEffect(() => {
        getHectares();
    }, []);

    const handleHectareClick = (index) => {
        setActiveHectare(index);
        getTrees(index);
        setDashVisible(!dashVisible);
        setAnchor(null);
    };
    //payment
    const [selectedTrees, setSelectedTrees] = useState([]);
    const handleAddingToWallet = (index) => {
        if (selectedTrees.includes(index)) {
            setSelectedTrees(selectedTrees.filter((item) => item !== index));
        } else {
            setSelectedTrees([...selectedTrees, index]);
        }
    }
    const handleTreesPurchase = async () => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch('https://app.nftforest.ge/cashola/checkout.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                body: selectedTrees,
                mode: 'cors',
                credentials: 'include'
            });
            if (response.ok) {
                console.log('sucessfully posted data');
            } else {
                console.log('response not ok')
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className='marketplace'>
            <h2 className='main_heading'>Marketplace</h2>
            <div className="hectares">
                {hectares != null ?
                    hectares.map((hectar) => (
                        <Hectare key={hectar.id} index={hectar.id} region={hectar.region} onClick={handleHectareClick} />
                    ))
                    : 'loading'}
            </div>
            <Panel open={open} anchor={anchor} activeHectar={activeHectare} handleTreesPurchaseFn={handleTreesPurchase} isOpened={dashVisible} id={id} handlePopUpClick={handleClick} selectedTrees={selectedTrees} paymentClickChoice={handleAddingToWallet} trees={trees} />

        </div>

    );
};

export default Marketplace;
