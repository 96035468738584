import React, { useCallback, useEffect, useState } from 'react';
import './referals.scss'
import Confirmed from './referralsInfoTabs/Confirmed';
import Pending from './referralsInfoTabs/Pending';

const Referals = () => {
    const [uuid, setUuid] = useState(null);
    const [refCount, setRefCount] = useState(0);
    useEffect(() => {
        const UserIdentification = async () => {
            const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
            try {
                const response = await fetch('https://app.nftforest.ge/profile/whoami.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                        'User-Agent': 'EricLystra',
                        'x-auth': 'NFTForest',
                        'x-api-key': apiKey,
                    },
                    mode: 'cors',
                    credentials: 'include'
                });

                if (response.ok) {
                    const userData = await response.json();
                    setUuid(userData[0].uuid); // Use optional chaining here
                } else {
                    console.error('Identification failed');
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        };
        UserIdentification();

    }, []);
    const milestonesData = [
        { subtext: 'A Forever NFT is an ultra rare NFT from a singular event that only occurs once!', name: "Milestone 1 Reward: A Forever NFT - Paulownia Sponsor", progress: "0/1" },
        { subtext: 'A Forever NFT is an ultra rare NFT from a singular event that only occurs once!', name: "Milestone 2 Reward: NFT Series - Forestry Friendship", progress: "0/10" },
        { subtext: 'A Forever NFT is an ultra rare NFT from a singular event that only occurs once!', name: "Milestone 3 Reward: NFT Series - Lamberjack Adventures", progress: "0/5" },
        { subtext: 'A Forever NFT is an ultra rare NFT from a singular event that only occurs once!', name: "Milestone 4 Reward: NFT Series - Circle Of Life", progress: "0/1" }
    ]
    const [activeRefferalTab, setActiveRefferalTab] = useState(0);
    const handleCopyLink = () => {
        const linkInput = document.getElementById('referral_link_input');
        if (linkInput) {
            navigator.clipboard.writeText(linkInput.value)
                .then(() => {
                    alert('Link copied successfully!');
                    // Add any further handling here if needed
                })
                .catch((error) => {
                    alert('Failed to copy link:', error);
                    // Add any error handling here if needed
                });
        }
    };
    const getCount = useCallback(async () => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (response.ok) {
                const userData = await response.json();
                setRefCount(userData.referals.length);
            } else {
                console.error('Failed to fetch');
            }
        } catch (error) {
            console.error('Failed to:', error);
        }
    }, [uuid]);

    useEffect(() => {
        if (uuid) {
            getCount();
        }
    }, [uuid, getCount]);
// const getCount = async () => {
//     const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
//     try {
//         const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${apiKey}`,
//                 'User-Agent': 'EricLystra',
//                 'x-auth': 'NFTForest',
//                 'x-api-key': apiKey,
//             },
//             mode: 'cors',
//             credentials: 'include'
//         });

//         if (response.ok) {
//             const userData = await response.json();
//             setRefCount(userData.referals.length);
//         } else {
//             console.error('Failed to fetch');
//         }
//     } catch (error) {
//         console.error('Failed to:', error);
//     }
// };
// useEffect(() => {
//     if (uuid) {
//         getCount();
//     }
// }, [uuid]);


return (
    <div className="referals_wrapper">
        <div className="referals_heading">
            <div className="left_side">
                <h2>Share your experience!</h2>
                <h5>Share your unique referal link to others to save our nature together!</h5>
                <div className="referal_link_wrapper">
                    {uuid !== null ? <input id='referral_link_input' type="text" disabled value={`https://app.nftforest.ge/register/?ref=${uuid}`} /> : <input placeholder='loading...' disabled type='text' />}
                    <button onClick={handleCopyLink}>Copy Link</button>
                </div>
            </div>
            <div className="right_side">
                <div className="referal_count_wrap">
                    <div className="referal_count">
                        <p>Referal Count</p>
                    </div>
                    <div className='referal_count_number'>
                        <h1>{refCount && refCount}</h1>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div className="milestones">
            <div className="milestones_heading">
                <h2>Reward Progress</h2>
                <h5>Your path through the NFT Forest is displayed here.</h5>
            </div>
            <div className="milestones_body">
                {milestonesData.map((achievement, _index) => (
                    <div className='milestone_single'>
                        <div className="upper">
                            <div className="img_wrap">
                                <img src='https://placehold.co/150' alt='...' />
                            </div>
                            <div className="text_wrap">
                                <h2>{achievement.name}</h2>
                                <p>{achievement.subtext}</p>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="progress">
                                <span>{achievement.progress}</span>
                            </div>
                            <div className="progress_bar"></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <hr />
        <div className="referals_breakdown">
            <div className="referals_breakdown_heading">
                <h2>Refferals breakdown</h2>
                <h5>Information about your referrals.</h5>
            </div>
            <div className="referals_breakdown_nav">
                <button onClick={() => setActiveRefferalTab(0)} className={activeRefferalTab === 0 && 'active'}>Confirmed <span>0</span></button>
                <button onClick={() => setActiveRefferalTab(1)} className={activeRefferalTab === 1 && 'active'}>Pending <span>0</span></button>
            </div>
            <hr />
            <div className="referals_brealdown_body">
                {activeRefferalTab === 0 ? <Confirmed /> : <Pending />}
            </div>
        </div>
    </div>
);
};

export default Referals;
