// import React, { useState } from 'react';
// import { FormControl, Input, InputLabel, Select, MenuItem, Button } from '@mui/material';
// import '../profile.scss';

// const PersonalInfo = () => {
//     const initialFormData = {
//         displayName: 'nandesu',
//         email: 'john@example.com',
//         gender: 'Male',
//         birthday: '2000-07-21', // Changed format to ISO date
//     };

//     const [formData, setFormData] = useState(initialFormData);
//     const [editMode, setEditMode] = useState(false);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({ ...prevData, [name]: value }));
//     };

//     const postPersonalData = async () => {
//         const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d'; // Example key
//         try {
//             const response = await fetch('https://app.nftforest.ge/settings/update/personal.php', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${apiKey}`,
//                     'User-Agent': 'EricLystra',
//                     'x-auth': 'NFTForest',
//                     'x-api-key': apiKey,
//                 },
//                 body: JSON.stringify({
//                     birthDate: formData.birthday,
//                     gender: formData.gender,
//                     email: formData.email,
//                     displayName: formData.displayName,
//                 }),
//                 mode: 'cors',
//                 credentials: 'include',
//             });

//             if (response.ok) {
//                 console.log('Data saved successfully');
//             } else {
//                 console.error('Failed to save data');
//             }
//         } catch (error) {
//             console.error('Error posting data:', error);
//         }
//     };

//     return (
//         <form className='form'>
//             <h4>Personal Information</h4>
//             <FormControl>
//                 <InputLabel htmlFor="displayName">Display Name</InputLabel>
//                 <Input
//                     type="text"
//                     id="displayName"
//                     name="displayName"
//                     value={formData.displayName}
//                     onChange={handleInputChange}
//                     disabled={!editMode}
//                 />
//             </FormControl>
//             <FormControl>
//                 <InputLabel htmlFor="birthday">Birthday</InputLabel>
//                 <Input
//                     type="date"
//                     id="birthday"
//                     name="birthday"
//                     value={formData.birthday}
//                     onChange={handleInputChange}
//                     disabled={!editMode}
//                 />
//             </FormControl>
//             <FormControl>
//                 <InputLabel htmlFor="gender">Gender</InputLabel>
//                 <Select
//                     id="gender"
//                     name="gender"
//                     value={formData.gender}
//                     onChange={handleInputChange}
//                     disabled={!editMode}
//                 >
//                     <MenuItem value="Male">Male</MenuItem>
//                     <MenuItem value="Female">Female</MenuItem>
//                     <MenuItem value="Other">Other</MenuItem>
//                 </Select>
//             </FormControl>
//             <FormControl>
//                 <InputLabel htmlFor="email">Email</InputLabel>
//                 <Input
//                     type="text"
//                     id="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     disabled={!editMode}
//                 />
//             </FormControl>
//             <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
//                 <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={() => {
//                         if (editMode) {
//                             postPersonalData(); // Save the data
//                         }
//                         setEditMode(!editMode);
//                     }}
//                 >
//                     {editMode ? 'Save' : 'Edit'}
//                 </Button>
//                 {editMode && (
//                     <Button
//                         variant="outlined"
//                         color="secondary"
//                         onClick={() => setEditMode(false)}
//                     >
//                         Cancel
//                     </Button>
//                 )}
//             </div>
//         </form>
//     );
// };

// export default PersonalInfo;

import React, { useEffect, useState } from 'react';
import { FormControl, Input, InputLabel, Select, MenuItem, Button } from '@mui/material';
import '../profile.scss';

const PersonalInfo = () => {
    const [formData, setFormData] = useState({
        displayName: '',
        email: '',
        gender: '',
        birthday: '', // ISO format for date
    });

    const [editMode, setEditMode] = useState(false);
    const [initialData, setInitialData] = useState({});

    // Fetch initial data from the API on component mount
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const response = await fetch('https://app.nftforest.ge/settings/personal.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer YOUR_API_KEY',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const initialFormData = {
                        displayName: data[0].sponsor_name,
                        email: data[0].email,
                        gender: data[0].gender || '', // Default to empty string if null
                        birthday: data[0].birthDate || '', // Default to empty string if null
                    };

                    setFormData(initialFormData);
                    setInitialData(initialFormData); // Store the initial data for comparison
                } else {
                    console.error('Failed to fetch initial data');
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();
    }, []); // Empty dependency array means this effect runs once when the component mounts

    const hasFormDataChanged = () => {
        return JSON.stringify(formData) !== JSON.stringify(initialData);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const postPersonalData = async () => {
        try {
            const response = await fetch('https://app.nftforest.ge/settings/update/personal.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer YOUR_API_KEY',
                    'x-auth': 'NFTForest',
                    'x-api-key': 'YOUR_API_KEY',
                },
                body: JSON.stringify({
                    birthDate: formData.birthday,
                    gender: formData.gender,
                    email: formData.email,
                    displayName: formData.displayName,
                }),
            });

            if (response.ok) {
                console.log('Data saved successfully');
            } else {
                console.error('Failed to save data');
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }
    };

    const handleEditButtonClick = () => {
        if (editMode && hasFormDataChanged()) {
            // Only post data if form data has changed
            postPersonalData();
        }
        setEditMode(!editMode);
    };

    const handleCancelButtonClick = () => {
        setFormData(initialData); // Reset to initial data
        setEditMode(false);
    };

    return (
        <form className='form'>
            <h4>Personal Information</h4>
            <FormControl>
                <InputLabel htmlFor="displayName">Display Name</InputLabel>
                <Input
                    type="text"
                    id="displayName"
                    name="displayName"
                    value={formData.displayName}
                    onChange={handleInputChange}
                    disabled={!editMode}
                />
            </FormControl>
            <FormControl>
                <InputLabel htmlFor="birthday" className='birtday_label'>Birthday</InputLabel>
                <Input
                    type="date"
                    id="birthday"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleInputChange}
                    disabled={!editMode}
                />
            </FormControl>
            <FormControl>
                <InputLabel htmlFor="gender">Gender</InputLabel>
                <Select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    disabled={!editMode}
                >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={!editMode}
                />
            </FormControl>
            <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditButtonClick}
                >
                    {editMode ? 'Save' : 'Edit'}
                </Button>
                {editMode && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancelButtonClick}
                    >
                        Cancel
                    </Button>
                )}
            </div>
        </form>
    );
};

export default PersonalInfo;
