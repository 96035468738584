// import { List, Payment, Person, Search, Tag } from '@mui/icons-material'
// import React, { useEffect, useMemo, useRef, useState } from 'react'
// import './profile.scss'
// import PersonalInfo from './panels/PersonalInfo';
// import PaymentInfo from './panels/PaymentInfo';
// import PrivacyInfo from './panels/PrivacyInfo';
// // import ReactImageGallery from 'react-image-gallery';
// // import Referals from '../referals/Referals';
// import SocialMediaForm from './panels/SocialMediaForm';
// import { Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
// import LazyLoadImage from '../../SiteComponents/LazyLoadImage';
// import singleTree from '../../assets/singleTree.jpeg'

// const MyProfile = () => {
//     // const data = useMemo(() => [
//     //     { treeId: "1", treeName: "Tree 1", treeStatus: "sponsored" },
//     //     { treeId: "3", treeName: "Tree 3", treeStatus: "sponsored" },
//     //     { treeId: "5", treeName: "Tree 5", treeStatus: "sponsored" },
//     //     { treeId: "6", treeName: "Tree 6", treeStatus: "sponsored" },
//     //     { treeId: "11", treeName: "Tree 11", treeStatus: "pending" },
//     //     { treeId: "12", treeName: "Tree 12", treeStatus: "sponsored" },
//     //     { treeId: "15", treeName: "Tree 15", treeStatus: "sponsored" },
//     //     { treeId: "17", treeName: "Tree 17", treeStatus: "pending" },
//     //     { treeId: "63", treeName: "Tree 63", treeStatus: "pending" },
//     //     { treeId: "200", treeName: "Tree 200", treeStatus: "sponsored" },
//     //     { treeId: "413", treeName: "Tree 13", treeStatus: "pending" },
//     //     { treeId: "433", treeName: "Tree 33", treeStatus: "pending" },
//     //     { treeId: "453", treeName: "Tree 53", treeStatus: "pending" },
//     // ], []); // `useMemo` with an empty dependency array ensures `data` is stable
//     const [data, setData] = useState(null);
//     useEffect(() => {
//         const UserIdentification = async () => {
//             const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
//             try {
//                 const response = await fetch('https://app.nftforest.ge/profile/whoami.php', {
//                     method: 'GET',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${apiKey}`,
//                         'User-Agent': 'EricLystra',
//                         'x-auth': 'NFTForest',
//                         'x-api-key': apiKey,
//                     },
//                     mode: 'cors',
//                     credentials: 'include'
//                 });

//                 if (response.ok) {
//                     const userData = await response.json();
//                     setUuid(userData[0].uuid); // Use optional chaining here
//                 } else {
//                     console.error('Identification failed');
//                 }
//             } catch (error) {
//                 console.error('Error during login:', error);
//             }
//         };
//         UserIdentification();

//     }, []);
//     const getInfo = useCallback(async () => {
//         const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
//         try {
//             const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${apiKey}`,
//                     'User-Agent': 'EricLystra',
//                     'x-auth': 'NFTForest',
//                     'x-api-key': apiKey,
//                 },
//                 mode: 'cors',
//                 credentials: 'include'
//             });

//             if (response.ok) {
//                 const userData = await response.json();
//                 setData(userData.trees);
//             } else {
//                 console.error('Failed to fetch');
//             }
//         } catch (error) {
//             console.error('Failed to fetch:', error);
//         }
//     }, [uuid]);

//     useEffect(() => {
//         if (uuid) {
//             getInfo();
//         }
//     }, [uuid, getInfo]);


//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredData, setFilteredData] = useState(data);

//     useEffect(() => {
//         setFilteredData(
//             searchTerm
//                 ? data.filter((item) => item.treeId === searchTerm)
//                 : data
//         );
//     }, [searchTerm, data]);

//     const handleInputChange = (event) => {
//         const { value } = event.target;
//         setSearchTerm(value);
//     };

//     const [openPanel, setOpenPanel] = useState(0);
//     const [vids, setVids] = useState('closed');
//     const inputRef = useRef(null);

//     useEffect(() => {
//         const preventWheel = (event) => {
//             if (inputRef.current.contains(document.activeElement)) {
//                 event.preventDefault(); // Prevents the default scroll behavior
//             }
//         };

//         window.addEventListener('wheel', preventWheel, { passive: false });

//         return () => {
//             window.removeEventListener('wheel', preventWheel);
//         };
//     }, []);
//     const toggleVids = () => {
//         vids === 'closed' ? setVids('open') : setVids('closed')
//     }
//     const nav = [
//         { title: "Personal Information", icon: <Person />, elem: <PersonalInfo /> },
//         { title: "Payments", icon: <Payment />, elem: <PaymentInfo /> },
//         // { title: "Referals", icon: <Link />, elem: <Referals /> },
//         { title: "Privacy", icon: <List />, elem: <PrivacyInfo /> },
//         { title: "Social Media", icon: <Tag />, elem: <SocialMediaForm /> }
//     ]
//     return (
//         <div className='profile_wrapper'>
//             <div className="profile_nav">
//                 <div onClick={() => toggleVids()} className="prof_nav_btn">
//                     {vids === 'closed' ? 'My Trees' : 'View Profile Information'}
//                 </div>
//             </div>
//             {vids === 'closed' ?
//                 <div className="profile_dash">
//                     <div className="profile_navigation">
//                         {nav.map((single, _index) => (
//                             <button onClick={() => setOpenPanel(_index)} className={`profile_nav_btn ${openPanel === _index ? 'active' : ''}`}>
//                                 {single.icon}
//                                 {single.title}
//                             </button>
//                         ))}
//                     </div>
//                     <div className="profile_panel">
//                         {nav[openPanel].elem}
//                     </div>
//                 </div>
//                 :
//                 <div className='profile_materials'>
//                     {/* <div className="drone_vid">
//                         <iframe
//                             width="90%"
//                             height="80%"
//                             frameBorder={0}
//                             src="https://www.youtube.com/embed/DeumyOzKqgI?si=yBZe8PALXot-wU9M"
//                             title="Drone Footage"
//                             allowFullScreen></iframe>
//                     </div> */}
//                     <div className="imgs">
//                         {/* <h3>Photos</h3>
//                         <ReactImageGallery items={images} /> */}
//                         <div className="search_trees">
//                             <TextField
//                                 type="number"
//                                 variant="outlined"
//                                 label='tree'
//                                 ref={inputRef}
//                                 onChange={handleInputChange}
//                                 InputProps={{
//                                     startAdornment: (
//                                         <Search />
//                                     ),
//                                 }}
//                                 sx={{ width: '100%' }}
//                             />
//                         </div>
//                         <Grid container spacing={2} style={{ padding: '1rem' }}>
//                             {filteredData.map((item) => (
//                                 <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
//                                     <Card>
//                                         <CardContent>
//                                             <LazyLoadImage
//                                                 src={singleTree}
//                                                 alt={`Apartment ${item.id}`}
//                                                 placeholder="Loading..."
//                                                 style={{ height: 300 }}
//                                             />
//                                         </CardContent>
//                                         <Divider />
//                                         <CardContent>
//                                             <Typography variant="h6">
//                                                 apartment N{item.treeId}
//                                             </Typography>
//                                             <Typography>name: {item.treeName}</Typography>
//                                             <Typography>status: {item.treeStatus}</Typography>
//                                         </CardContent>
//                                     </Card>
//                                 </Grid>
//                             ))}
//                         </Grid>
//                     </div>
//                 </div>
//             }
//         </div>
//     )
// }

// export default MyProfile

import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { List, Payment, Person, Search, Tag } from '@mui/icons-material';
import { Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import PersonalInfo from './panels/PersonalInfo';
import PaymentInfo from './panels/PaymentInfo';
import PrivacyInfo from './panels/PrivacyInfo';
import SocialMediaForm from './panels/SocialMediaForm';
import LazyLoadImage from '../../SiteComponents/LazyLoadImage';
import singleTree from '../../assets/singleTree.jpeg';
import './profile.scss';

const MyProfile = () => {
    const [uuid, setUuid] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const UserIdentification = async () => {
            const apiKey = 'your_api_key_here';
            try {
                const response = await fetch('https://app.nftforest.ge/profile/whoami.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                    },
                });

                if (response.ok) {
                    const userData = await response.json();
                    setUuid(userData[0]?.uuid);
                } else {
                    console.error('Identification failed');
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        };

        UserIdentification();
    }, []);

    const getInfo = useCallback(async () => {
        const apiKey = 'your_api_key_here';
        try {
            const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                },
            });

            if (response.ok) {
                const userData = await response.json();
                setData(userData.trees);
            } else {
                console.error('Failed to fetch');
            }
        } catch (error) {
            console.error('Failed to fetch:', error);
        }
    }, [uuid]);

    useEffect(() => {
        if (uuid) {
            getInfo();
        }
    }, [uuid, getInfo]);

    const [searchTerm, setSearchTerm] = useState('');

    const filteredData = useMemo(() => {
        if (searchTerm) {
            return data.filter((item) => item.treeId === searchTerm);
        }
        return data;
    }, [data, searchTerm]);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const [openPanel, setOpenPanel] = useState(0);
    const [vids, setVids] = useState('closed');

    const inputRef = useRef(null);

    useEffect(() => {
        const preventWheel = (event) => {
            if (inputRef.current && inputRef.current.contains(document.activeElement)) {
                event.preventDefault();
            }
        };

        window.addEventListener('wheel', preventWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', preventWheel);
        };
    }, []);

    const toggleVids = () => {
        setVids(vids === 'closed' ? 'open' : 'closed');
    };

    const nav = [
        { title: "Personal Information", icon: <Person />, elem: <PersonalInfo /> },
        { title: "Payments", icon: <Payment />, elem: <PaymentInfo /> },
        { title: "Privacy", icon: <List />, elem: <PrivacyInfo /> },
        { title: "Social Media", icon: <Tag />, elem: <SocialMediaForm /> },
    ];

    return (
        <div className='profile_wrapper'>
            <div className="profile_nav">
                <div onClick={toggleVids} className="prof_nav_btn">
                    {vids === 'closed' ? 'My Trees' : 'Settings'}
                </div>
            </div>


            {vids === 'closed' ? (
                <div className="profile_dash">
                    <div className="profile_navigation"> 
                        {nav.map((single, index) => (
                            <button
                                key={index}
                                onClick={() => setOpenPanel(index)}
                                className={`profile_nav_btn ${openPanel === index ? 'active' : ''}`}
                            >
                                {single.icon} {single.title}
                            </button>
                        ))}
                    </div>
                    <div className="profile_panel">{nav[openPanel].elem}</div>
                </div>
            ) : (
                <div className='profile_materials'>
                    <div className="search_trees">
                        <TextField
                            type="number"
                            variant="outlined"
                            label='Tree ID'
                            ref={inputRef}
                            onChange={handleInputChange}
                            InputProps={{ startAdornment: <Search /> }}
                            sx={{ width: '100%' }}
                        />
                    </div>

                    <Grid container spacing={2} style={{ padding: '1rem' }}>
                        {filteredData.map((item) => (
                            <Grid item xs={12} sm={12} md={6} lg={4} key={item.treeId}>
                                <Card>
                                    <CardContent>
                                        <LazyLoadImage
                                            src={singleTree}
                                            alt={`Tree ${item.treeId}`}
                                            placeholder="Loading..."
                                            style={{ height: 300 }}
                                        />
                                    </CardContent>
                                    <Divider />
                                    <CardContent>
                                        <Typography variant="h6">Tree ID: {item.treeId}</Typography>
                                        <Typography>Tree Name: {item.treeName}</Typography>
                                        <Typography>Status: {item.treeStatus}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default MyProfile;
