import { Error } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import './demand.scss'
import { useTranslation } from 'react-i18next'

const SignInDemand = () => {
    const { t } = useTranslation();
    return (
        <div className='demand'>
            <h4 style={{ textAlign: 'center' }}>{t('signInDemand')}</h4>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
                <Link to={'/sign-in'}>{t('signIn')}</Link>
                <Link to={'/register'}>{t('signUp')}</Link>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <Error style={{ fontSize: '100px', color: 'green' }} />
            </div>
            <div className="our_vid">
                <iframe
                    width="100%"
                    height="400px"
                    frameBorder={0}
                    src="https://www.youtube.com/embed/zlx0B12LA_o"
                    title="Our Inforamtional Video"
                    allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default SignInDemand