// import React, { useState } from 'react';
// import Filter from 'bad-words';
// import { Button, FormControl, FormControlLabel, Input, Radio, RadioGroup, Typography } from '@mui/material';
// import './register.scss';
// import { useNavigate } from 'react-router-dom';

// const RegistrationForm = () => {
//     const nav = useNavigate();
//     const [firstName, setFirstName] = useState('');
//     const [surname, setSurname] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [username, setUsername] = useState('');
//     const [isOver18, setIsOver18] = useState('no');
//     const [usernameExists, setUsernameExists] = useState(false);
//     const [containsBadWords, setContainsBadWords] = useState(false);

//     const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
//     const handleRegistration = async () => {

//         // Check for bad words in the username
//         const filter = new Filter();
//         if (filter.isProfane(username)) {
//             setContainsBadWords(true);
//             return; // Do not proceed with registration
//         }

//         try {
//             // Check if the username already exists
//             const usernameResponse = await fetch('https://app.nftforest.ge/signup/username.php', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${apiKey}`,
//                     'User-Agent': 'EricLystra',
//                     'x-auth': 'NFTForest',
//                     'x-api-key': apiKey,
//                 },
//                 body: JSON.stringify({
//                     username
//                 }),
//                 mode: 'cors',
//                 credentials: 'include'
//             });

//             const usernameResponseData = await usernameResponse.json();
//             console.log(usernameResponseData);

//             if (usernameResponseData === 1) {
//                 setUsernameExists(true);
//             } else {
//                 try {
//                     // Proceed with registration
//                     const response = await fetch('https://app.nftforest.ge/signup/', {
//                         method: 'POST',
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'Authorization': `Bearer ${apiKey}`,
//                             'User-Agent': 'EricLystra',
//                             'x-auth': 'NFTForest',
//                             'x-api-key': apiKey,
//                         },
//                         body: JSON.stringify({
//                             username,
//                             email,
//                             password,
//                             firstName,
//                             surname,
//                         }),
//                         mode: 'cors',
//                         credentials: 'include'
//                     });
//                     const responseData = await response.json();
//                     console.log(responseData); // Handle success response
//                     alert('Registration sucessful!');
//                     nav("/sign-in");
//                     // Add any further handling or redirection here
//                 } catch (error) {
//                     console.error(error); // Handle error
//                     // Add any error handling or redirection here
//                 }
//             }
//         } catch (error) {
//             console.error(error);
//         }
//     };

//     return (
//         <form className='registration_form' action='POST' method='POST'>
//             <h3>Register and save the nature!</h3>
//             <FormControl sx={{ width: '100%' }}>
//                 <label>Username</label>
//                 <Input
//                     slotProps={{ input: { placeholder: 'Username', type: 'text' } }}
//                     value={username}
//                     onChange={(e) => setUsername(e.target.value)}
//                 />
//                 {usernameExists ? (
//                     <Typography variant="body2" color="error">
//                         Username already exists!
//                     </Typography>
//                 ) : ''}
//                 {containsBadWords ? (
//                     <Typography variant="body2" color="error">
//                         Username contains offensive words. Please choose another username.
//                     </Typography>
//                 ) : ''}
//             </FormControl>
//             <FormControl sx={{ width: '100%' }}>
//                 <label>Email</label>
//                 <Input
//                     slotProps={{ input: { placeholder: 'Email', type: 'email' } }}
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                 />
//             </FormControl>
//             <div className="names">
//                 <FormControl>
//                     <label>First Name</label>
//                     <Input
//                         slotProps={{ input: { placeholder: 'First Name', type: 'text' } }}
//                         value={firstName}
//                         onChange={(e) => setFirstName(e.target.value)}
//                     />
//                 </FormControl>
//                 <FormControl>
//                     <label>Surname</label>
//                     <Input
//                         slotProps={{ input: { placeholder: 'Surname', type: 'text' } }}
//                         value={surname}
//                         onChange={(e) => setSurname(e.target.value)}
//                     />
//                 </FormControl>
//             </div>

//             <FormControl sx={{ width: '100%' }}>
//                 <label>Password</label>
//                 <Input
//                     slotProps={{ input: { placeholder: 'Password', type: 'password' } }}
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                 />
//             </FormControl>

//             <FormControl component="fieldset" sx={{ width: '100%' }}>
//                 <label>Are you 18?</label>
//                 <RadioGroup
//                     aria-label="over18"
//                     name="over18"
//                     value={isOver18}
//                     onChange={(e) => setIsOver18(e.target.value)}
//                     sx={{ display: 'flex', flexDirection: 'row' }}
//                 >
//                     <FormControlLabel value='yes' control={<Radio />} label="Yes" />
//                     <FormControlLabel value='no' control={<Radio />} label="No" />
//                 </RadioGroup>
//             </FormControl>

//             <Button className='submit_btn' onClick={handleRegistration} disabled={isOver18 === 'no'}>
//                 Register
//             </Button>
//         </form>
//     );
// };

// export default RegistrationForm;
import React, { useState } from 'react';
import Filter from 'bad-words';
import { Button, FormControl, FormControlLabel, Input, Radio, RadioGroup, Typography } from '@mui/material';
import './register.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const RegistrationForm = () => {
    const nav = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [isOver18, setIsOver18] = useState('no');
    const [usernameExists, setUsernameExists] = useState(false);
    const [emailExists, setEmailExists] = useState(false);
    const [containsBadWords, setContainsBadWords] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');
    console.log(ref);

    const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
    // const handleRegistration = async () => {

    //     // Check for bad words in the username
    //     const filter = new Filter();
    //     if (filter.isProfane(username)) {
    //         setContainsBadWords(true);
    //         return; // Do not proceed with registration
    //     }

    //     try {
    //         // Check if the username already exists
    //         const usernameResponse = await fetch('https://app.nftforest.ge/signup/username.php', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${apiKey}`,
    //                 'User-Agent': 'EricLystra',
    //                 'x-auth': 'NFTForest',
    //                 'x-api-key': apiKey,
    //             },
    //             body: JSON.stringify({
    //                 username
    //             }),
    //             mode: 'cors',
    //             credentials: 'include'
    //         });

    //         const usernameResponseData = await usernameResponse.json();
    //         console.log(usernameResponseData);

    //         if (usernameResponseData === 1) {
    //             setUsernameExists(true);
    //         } else {
    //             // Check if the email already exists
    //             const emailResponse = await fetch('https://app.nftforest.ge/signup/email.php', {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${apiKey}`,
    //                     'User-Agent': 'EricLystra',
    //                     'x-auth': 'NFTForest',
    //                     'x-api-key': apiKey,
    //                 },
    //                 body: JSON.stringify({
    //                     email
    //                 }),
    //                 mode: 'cors',
    //                 credentials: 'include'
    //             });

    //             const emailResponseData = await emailResponse.json();
    //             console.log(emailResponseData);

    //             if (emailResponseData === 1) {
    //                 setEmailExists(true);
    //             } else {
    //                 try {
    //                     // Proceed with registration
    //                     const response = await fetch('https://app.nftforest.ge/signup/', {
    //                         method: 'POST',
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                             'Authorization': `Bearer ${apiKey}`,
    //                             'User-Agent': 'EricLystra',
    //                             'x-auth': 'NFTForest',
    //                             'x-api-key': apiKey,
    //                         },
    //                         body: JSON.stringify({
    //                             username,
    //                             email,
    //                             password,
    //                             firstName,
    //                             surname,
    //                         }),
    //                         mode: 'cors',
    //                         credentials: 'include'
    //                     });
    //                     const responseData = await response.json();
    //                     console.log(responseData); // Handle success response
    //                     alert('Registration successful!');
    //                     nav("/sign-in");
    //                     // Add any further handling or redirection here
    //                 } catch (error) {
    //                     console.error(error); // Handle error
    //                     // Add any error handling or redirection here
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const handleRegistration = async () => {
        // Check for bad words in the username
        const filter = new Filter();
        if (filter.isProfane(username)) {
            setContainsBadWords(true);
            return; // Do not proceed with registration
        }

        try {
            // Check if the UUID is required (i.e., if ref is present)
            if (ref) {
                // Check if the UUID is valid
                const uuidResponse = await fetch('https://app.nftforest.ge/signup/uuid.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                        'User-Agent': 'EricLystra',
                        'x-auth': 'NFTForest',
                        'x-api-key': apiKey,
                    },
                    body: JSON.stringify({
                        ref
                    }),
                    mode: 'cors',
                    credentials: 'include'
                });

                const uuidResponseData = await uuidResponse.json();
                console.log(uuidResponseData);

                if (uuidResponseData === 0) {
                    // UUID is not valid, do not proceed with registration
                    alert("UUID is not valid");
                    return;
                }
            }

            // Check if the username already exists
            const usernameResponse = await fetch('https://app.nftforest.ge/signup/username.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                body: JSON.stringify({
                    username
                }),
                mode: 'cors',
                credentials: 'include'
            });

            const usernameResponseData = await usernameResponse.json();
            console.log(usernameResponseData);

            if (usernameResponseData === 1) {
                setUsernameExists(true);
                return; // Do not proceed with registration
            }

            // Proceed with the registration process
            const emailResponse = await fetch('https://app.nftforest.ge/signup/email.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                body: JSON.stringify({
                    email
                }),
                mode: 'cors',
                credentials: 'include'
            });

            const emailResponseData = await emailResponse.json();
            console.log(emailResponseData);

            if (emailResponseData === 1) {
                setEmailExists(true);
                return; // Do not proceed with registration
            }

            // Proceed with the registration
            const response = await fetch('https://app.nftforest.ge/signup/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                body: JSON.stringify({
                    username,
                    email,
                    password,
                    firstName,
                    surname,
                    ref
                }),
                mode: 'cors',
                credentials: 'include'
            });
            const responseData = await response.json();
            console.log(responseData); // Handle success response
            alert('Registration successful!');
            nav("/sign-in");
            // Add any further handling or redirection here
        } catch (error) {
            console.error(error);
            // Add any error handling or redirection here
        }
    };

    return (
        <form className='registration_form' action='POST' method='POST'>
            <h3>Register and save the nature!</h3>
            <FormControl sx={{ width: '100%' }}>
                <label>Username</label>
                <Input
                    slotProps={{ input: { placeholder: 'Username', type: 'text' } }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                {usernameExists ? (
                    <Typography variant="body2" color="error">
                        Username already exists!
                    </Typography>
                ) : ''}
                {containsBadWords ? (
                    <Typography variant="body2" color="error">
                        Username contains offensive words. Please choose another username.
                    </Typography>
                ) : ''}
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
                <label>Email</label>
                <Input
                    slotProps={{ input: { placeholder: 'Email', type: 'email' } }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                {emailExists ? (
                    <Typography variant="body2" color="error">
                        Email already exists!
                    </Typography>
                ) : ''}
            </FormControl>
            <div className="names">
                <FormControl>
                    <label>First Name</label>
                    <Input
                        slotProps={{ input: { placeholder: 'First Name', type: 'text' } }}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </FormControl>
                <FormControl>
                    <label>Surname</label>
                    <Input
                        slotProps={{ input: { placeholder: 'Surname', type: 'text' } }}
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        required
                    />
                </FormControl>
            </div>

            <FormControl sx={{ width: '100%' }}>
                <label>Password</label>
                <Input
                    slotProps={{ input: { placeholder: 'Password', type: 'password' } }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </FormControl>

            <FormControl component="fieldset" sx={{ width: '100%' }}>
                <label>Are you 18 or older?</label>
                <RadioGroup
                    aria-label="over18"
                    name="over18"
                    value={isOver18}
                    onChange={(e) => setIsOver18(e.target.value)}
                    sx={{ display: 'flex', flexDirection: 'row' }}
                >
                    <FormControlLabel value='yes' control={<Radio />} label="Yes" />
                    <FormControlLabel value='no' control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>

            {ref &&
                <FormControl sx={{ width: '100%' }}>
                    <label>Refferal</label>
                    <Input
                        slotProps={{ input: {type: 'text' } }}
                        value={ref}
                        disabled
                    />
                </FormControl>
            }
            <Button className='submit_btn' onClick={handleRegistration} disabled={isOver18 === 'no'}>
                Register
            </Button>
        </form>
    );
};

export default RegistrationForm;
