import React from 'react'
import DropzoneImg from '../../SiteComponents/dropzones/DropzoneImg';
import { Link } from 'react-router-dom';

const UploadImage = () => {
    // const uploadFileToServer = async (file) => {
    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         const response = await fetch('https://app.nftforest.ge/up/load.php', {
    //             method: 'POST',
    //             body: formData
    //         });

    //         if (response.ok) {
    //             const result = await response.json();
    //             console.log('File uploaded to server. Image URL:', result.imageUrl);
    //         } else {
    //             console.error('File upload failed.');
    //         }
    //     } catch (error) {
    //         console.error('Error uploading file:', error);
    //     }
    // };

    return (
        <div style={{width:'100%', textAlign:'center'}}>
            {/* <DropzoneComponent
                config={{ postUrl: "https://app.nftforest.ge/up/load.php" }}
                eventHandlers={{ addedfile: handleFileChange, maxfilesexceeded: handleFileExceeded }}
                djsConfig={{ autoProcessQueue: false }}
            />
            <div className="preview">
                {files.map((file) => (
                    <img src={file.dataURL} alt={file.name} key={file.name} />
                ))}
            </div> */}
            <DropzoneImg />
            <Link to={'/'}>Back</Link>
            {/* <DropzoneComponent onFileLoad={handleFileLoad} /> */}
        </div>
    )
}
export default UploadImage