import React from 'react'
import Leaderboard from './components/leaderboard/Leaderboard'

const Test = () => {

    return (
        // <Referals referralsArray={[{ "id": "16", "referalUUID": "4c656657-3132-4551-817a-6a796c334e65", "referalDisplayName": "Xuser Nine", "dateOfJoining": "2024-04-15", "referalAvatar": "xuser9.jpg", "referalRouteUsername": "xuser9", "referalScore": "05", "referalReferals": 2, "referalTrees": 5 }]
        // } />
        // <Socials socials={{"firstName":"Xuser","surName":"Nine","socialMediaArray":[{"name":"Facebook","link":""},{"name":"Twitter","link":""},{"name":"Instagram","link":""},{"name":"LinkedIn","link":""},{"name":"YouTube","link":""}]}} />
        <Leaderboard />
    )
}

export default Test