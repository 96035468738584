// authReducer.js
const initialState = {
  isAuthenticated: false,
  // other state properties...
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_AUTHENTICATION":
      return {
        ...state,
        isAuthenticated: action.payload,
        // Update other state properties based on the authentication action if needed
      };

    // Handle other actions if necessary...

    default:
      return state;
  }
};

export default AuthReducer;
