import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './leaderboard.scss';

const columns = [
    { id: 'place', label: 'Place', minWidth: 50 },
    { id: 'img', label: 'Photo', minWidth: 20 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'when', label: 'Date of Joining', minWidth: 100 },
    { id: 'score', label: 'Score', minWidth: 100 },
    { id: 'referrals', label: 'Referrals', minWidth: 100 },
    { id: 'trees', label: 'Trees', minWidth: 100 },
];

// Convert database date to more readable format
function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });
}

function createData(place, imgSrc, name, when, score, referrals, trees, uuid) {
    return { place, imgSrc, name, when, score, referrals, trees, uuid };
}

const Leaderboard = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        const fetchData = async () => {
            const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
            try {
                const response = await fetch('https://app.nftforest.ge/leaderboard', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                        'User-Agent': 'EricLystra',
                        'x-auth': 'NFTForest',
                        'x-api-key': apiKey,
                    },
                    mode: 'cors',
                    credentials: 'include'
                });

                if (response.ok) {
                    const dataa = await response.json();
                    setData(dataa); // Use optional chaining here
                } else {
                    console.error('Identification failed');
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        };
        fetchData();

    }, []);

    // Create rows from the data
    const rows = data?.map((item, index) => {
        return createData(
            index + 1,
            item.imgsrc, // Assuming this is a base64-encoded image
            item.name,
            formatDate(item.when),
            item.score,
            item.referrals,
            item.trees,
            item.uuid // UUID for redirection
        );
    });

    return (
        <div className="leaderboard">
            <h2>Leaderboard - Project</h2>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" className="leaderboard_table">
                        <TableHead>
                            <TableRow className="tableHead">
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover key={row.place}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            if (column.id === 'img') {
                                                return (
                                                    <TableCell key={column.id}>
                                                        <Link to={`/users/${row.uuid}`}>
                                                            <img
                                                                style={{ borderRadius: '50%', width: '30px', height: '30px' }}
                                                                src={`https://app.nftforest.ge/assets/img/${row.imgSrc}`}
                                                                alt={row.name}
                                                                className="user-photo"
                                                            />
                                                        </Link>
                                                    </TableCell>
                                                );
                                            }
                                            return (
                                                <TableCell key={column.id}>
                                                    {column.id === 'name' ? (
                                                        <Link to={`/users/${row.uuid}`}>
                                                            {value}
                                                        </Link>
                                                    ) : (
                                                        value
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
};

export default Leaderboard;
