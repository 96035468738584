import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './referals.scss';

const columns = [
    { id: 'id', label: '#', minWidth: 50 },
    { id: 'referalAvatar', label: 'Photo', minWidth: 50 },
    { id: 'referalDisplayName', label: 'Name', minWidth: 170 },
    { id: 'dateOfJoining', label: 'Date of joining', minWidth: 100 },
    { id: 'referalScore', label: 'Score', minWidth: 100 },
    { id: 'referalReferals', label: 'Referrals', minWidth: 170 },
    { id: 'referalTrees', label: 'Trees', minWidth: 170 },
    // Removed the UUID column
];

const Referals = ({ referralsArray }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="referals">
            <h2>Your Referrals</h2>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table" className="referals_table">
                        <TableHead>
                            <TableRow className="tableHead">
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label === 'Photo' ? '' : column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {referralsArray &&
                                referralsArray
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                if (column.id === 'referalAvatar') {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link to={`/users/${row.referalUUID}`}>
                                                                <img
                                                                    style={{ borderRadius: '50%', width: '30px' }}
                                                                    src={`https://app.nftforest.ge/assets/img/${value}`}
                                                                    alt={`${row.referalDisplayName}`}
                                                                    className="user-photo"
                                                                />
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                }

                                                if (column.id === 'referalDisplayName') {
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            <Link
                                                                style={{ textDecoration: 'none' }}
                                                                to={`/users/${row.referalUUID}`}
                                                            >
                                                                {value}
                                                            </Link>
                                                        </TableCell>
                                                    );
                                                }

                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={referralsArray.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ overflow: 'hidden', color: 'black' }}
                />
            </Paper>
        </div>
    );
};

export default Referals;
