import { Button, FormControl, Input, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './signin.scss'

const SignIn = () => {
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [loginError, setLoginError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const nav = useNavigate();
    const handleLogin = async () => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch('https://app.nftforest.ge/signin/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                body: JSON.stringify({ username, password }),
                mode: 'cors',
                credentials: 'include'
            });

            if (response.ok) {
                // Handle successful login
                console.log('Login successful');
                nav("/");
            } else {
                console.error('Login failed');
                setLoginError(true);
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };



    return (
        <div className='sign_in'>
            <h2>Sign In</h2>
            <form action='POST' method='POST'>
                <FormControl sx={{ width: '100%' }}>
                    <label>Username</label>
                    <Input
                        slotProps={{ input: { placeholder: 'Username', type: 'text' } }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                    <label>Password</label>
                    <Input
                        slotProps={{ input: { placeholder: 'Password' } }}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyPress}
                        endAdornment={
                            <InputAdornment position='end'>
                                <Button
                                    tabIndex={-1}
                                    onClick={() => setShowPassword((prev) => !prev)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    className='eye_btn'
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </Button>
                            </InputAdornment>
                        }
                    />

                </FormControl>
                {loginError ? <Typography variant="body2" color="error">Either username or password is wrong!</Typography> : ''}
                <Button className='submit_btn' onClick={() => handleLogin()}>
                    Log In
                </Button>
                <Link to={'/register'}>
                    Register
                </Link>
            </form>
        </div>
    )

}

export default SignIn