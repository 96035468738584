import React, { useCallback, useEffect, useState } from 'react';
import forest from '../../assets/forest.jpg';
import './profile.scss';
import Trees from './panels/Trees';
import Referals from './panels/Referals';
import Socials from './panels/Socials';
import { Link } from 'react-router-dom';

const Profile = () => {
    const [navSt, setNavSt] = useState(1);
    const [uuid, setUuid] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const UserIdentification = async () => {
            const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
            try {
                const response = await fetch('https://app.nftforest.ge/profile/whoami.php', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                        'User-Agent': 'EricLystra',
                        'x-auth': 'NFTForest',
                        'x-api-key': apiKey,
                    },
                    mode: 'cors',
                    credentials: 'include'
                });

                if (response.ok) {
                    const userData = await response.json();
                    setUuid(userData[0].uuid); // Use optional chaining here
                } else {
                    console.error('Identification failed');
                }
            } catch (error) {
                console.error('Error during login:', error);
            }
        };
        UserIdentification();

    }, []);
    // const getInfo = async () => {
    //     const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
    //     try {
    //         const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${apiKey}`,
    //                 'User-Agent': 'EricLystra',
    //                 'x-auth': 'NFTForest',
    //                 'x-api-key': apiKey,
    //             },
    //             mode: 'cors',
    //             credentials: 'include'
    //         });

    //         if (response.ok) {
    //             const userData = await response.json();
    //             setUser(userData);
    //         } else {
    //             console.error('Failed to fetch');
    //         }
    //     } catch (error) {
    //         console.error('Failed to:', error);
    //     }
    // };
    // useEffect(() => {
    //     if (uuid) {
    //         getInfo();
    //     }
    // }, [uuid]);

    const getInfo = useCallback(async () => {
        const apiKey = '0a032477a6f2cc1cd91960d1e554561151f0458ca433e98b848d';
        try {
            const response = await fetch(`https://app.nftforest.ge/profile/view/?uuid=${uuid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                    'User-Agent': 'EricLystra',
                    'x-auth': 'NFTForest',
                    'x-api-key': apiKey,
                },
                mode: 'cors',
                credentials: 'include'
            });

            if (response.ok) {
                const userData = await response.json();
                setUser(userData);
            } else {
                console.error('Failed to fetch');
            }
        } catch (error) {
            console.error('Failed to fetch:', error);
        }
    }, [uuid]);

    useEffect(() => {
        if (uuid) {
            getInfo();
        }
    }, [uuid, getInfo]);




    const toggleActive = (id) => {
        if (id === navSt) {
            setNavSt(0);
        } else {
            setNavSt(id);
        }
    };
    let nav;
    if (user !== null) {
        nav = [
            { clickable: true, elem: <Trees treesArray={user.trees} />, id: 1, num: user.trees.length, txt: 'Trees' },
            { clickable: true, elem: <Referals referralsArray={user.referals} />, id: 2, num: user.referals.length, txt: 'Referals' },
            { clickable: true, elem: <Socials socials={user.SocialMedia} />, id: 3, num: user.SocialMedia.socialMediaArray.filter(item => item.link && item.link.trim() !== "").length, txt: 'Soc. media' },
            { clickable: false, id: 4, num: 0, txt: 'CO2 capture rate' },
            { clickable: false, id: 5, num: 0, txt: 'Score' }
        ];
    } else {
        nav = [
            { clickable: false, elem: <Trees />, id: 1, num: 1, txt: 'Trees' },
            { clickable: false, elem: <Referals />, id: 2, num: 12, txt: 'Referals' },
            { clickable: false, elem: <Socials />, id: 3, num: 3, txt: 'Soc. media' },
            { clickable: false, id: 4, num: 0, txt: 'CO2 capture rate' },
            { clickable: false, id: 5, num: 0, txt: 'Score' }
        ];
    }
    return (
        <div className='profile'>
            <div className="profile_widget">
                <div className="profile_bg" style={{ backgroundImage: `url(${forest})` }}>
                    {/* <span onClick={() => navigate('/logout/')} className='logout_btn'>Log out</span> */}
                    <a href='https://app.nftforest.ge/logout/' className='logout_btn'>Log out</a>
                    <div className="personal_info">
                        <div className='profile_img_wrap'>
                            <Link to={'/uploadImage'}>
                                {user ? (
                                    <div style={{ backgroundImage: `url("assets/img/${user.user?.profilePictureSrc}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100%' }}></div>
                                ) : <img src='' alt='userProfile' />}
                            </Link>
                        </div>
                        {user && (
                            <>
                                <h5>{user.user?.displayName}</h5>
                                <h6>@{user.user?.username}</h6>
                            </>
                        )}
                    </div>
                </div>
                <div className="profile_nav">
                    {nav && nav.map((btn) => (
                        btn.clickable ? (
                            <div key={btn.id} onClick={() => toggleActive(btn.id)} className={`btn ${navSt === btn.id ? 'active' : ''}`}>
                                <h5>{btn.num}</h5>
                                <p>{btn.txt}</p>
                            </div>
                        ) : (
                            <div key={btn.id} className={`btn nocursor`}>
                                <h5>{btn.num}</h5>
                                <p>{btn.txt}</p>
                            </div>
                        )
                    ))}
                </div>
            </div>
            <div className="profile_panel">
                {user ? (
                    navSt === 0 ? '' : nav[navSt - 1]?.elem
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default Profile;
