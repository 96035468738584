// import React, { useState, useCallback } from 'react';
// import { useDropzone } from 'react-dropzone';
// import axios from 'axios';

// const DropzoneImg = () => {
//     const [uploadedImage, setUploadedImage] = useState(null);

//     const onDrop = useCallback(async (acceptedFiles) => {
//         const file = acceptedFiles[0];
//         if (file) {
//             const formData = new FormData();
//             formData.append('file', file);
//             console.log(formData);
//             try {
//                 const response = await axios.post('https://app.nftforest.ge/up/load.php', formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                     },
//                 });

//                 if (response.status === 200) {
//                     const responseData = response.data;
//                     console.log('File uploaded successfully:', responseData);
//                     setUploadedImage(URL.createObjectURL(file)); // Store the image URL for preview
//                 } else {
//                     console.error('Error uploading file:', response.statusText);
//                     // Handle error, e.g., show an error message
//                 }
//             } catch (error) {
//                 console.error('Error uploading file:', error);
//                 // Handle error, e.g., show an error message
//             }
//         }
//     }, []);

//     const { getRootProps, getInputProps, isDragActive } = useDropzone({
//         onDrop,
//         accept: 'image/jpeg, image/png',
//         maxFiles: 1,
//     });

//     return (
//         <div>
//             <div style={{width:200, height:100, borderStyle:'dotted'}} {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
//                 <input {...getInputProps()} />
//                 <p>Drag & drop an image file here, or click to select one</p>
//             </div>
//             {uploadedImage && (
//                 <div>
//                     <p>Uploaded Image Preview:</p>
//                     <img src={uploadedImage} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '300px' }} />
//                 </div>
//             )}
//         </div>
//     );
// };

// export default DropzoneImg;
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import classNames from 'classnames'; // To manage conditional class assignment
import './dropzone.scss'
import cloud from '../../assets/cloud.png'
const DropzoneImg = () => {
    const [uploadedImage, setUploadedImage] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            setIsUploading(true); // Set uploading state to true
            try {
                const response = await axios.post('https://app.nftforest.ge/up/load.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    const responseData = response.data;
                    console.log('File uploaded successfully:', responseData);
                    setUploadedImage(URL.createObjectURL(file)); // Store the image URL for preview
                } else {
                    console.error('Error uploading file:', response.statusText);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
            setIsUploading(false); // Reset uploading state
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpeg, image/png',
        maxFiles: 1,
    });

    return (
        <div className="upload">
            <h1>Upload Img</h1>
            <div
                {...getRootProps()}
                className={classNames("dropzone", { highlight: isDragActive })}
            >
                <input {...getInputProps()} />
                {isUploading ? (
                    <h3 className="img-title uploading">Uploading...</h3>
                ) : (
                    <>
                        <h3 className="img-title">Drag and drop to upload</h3>
                        <img src={cloud} alt='cloud' />
                        <p className="img-subtitle">Or click to select a file</p>
                    </>
                )}
            </div>
            {uploadedImage && (
                <div>
                    <h3 className="img-title done">Uploaded Image Preview:</h3>
                    <img src={uploadedImage} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                </div>
            )}
        </div>
    );
};

export default DropzoneImg;
